import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Link,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const legalInfoList = [
  {
    header: 'Умови використання',
    description: 'Terms of Use - детально описують правила та умови використання вебсайту або сервісу',
    link: 'Умови використання',
    path: 'term-of-use',
  },
  {
    header: 'Політика конфіденційності',
    description:
      'Privacy Policy - пояснює, як збираються, використовуються та захищаються персональні дані користувачів',
    link: 'Умови використання',
    path: 'term-of-use',
  },
  {
    header: 'Політика Cookie',
    description: 'Cookie Policy - інформує про використання файлів cookie на вебсайті',
    link: 'Політика Cookie',
    path: 'cookie-policy',
  },
  {
    header: 'Політика повернення',
    description: 'Return Policy - описує умови та процес повернення товарів або коштів',
    link: 'Політика повернення',
    path: 'return-policy',
  },
  {
    header: 'Декларація про відповідальність',
    description: 'Disclaimer - зазначає обмеження відповідальності за використання інформації на сайті',
    link: 'Декларація про відповідальність',
    path: 'disclaimer',
  },
];

const LegalInfo = () => (
  <>
    <Helmet>
      <title> Правова інформація </title>
    </Helmet>
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Правова інформація
        </Typography>
      </Stack>
      <Box>
        {legalInfoList.map((element) => (
          <>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ fontWeight: 'bold' }}
              >
                {element.header}
              </AccordionSummary>
              <AccordionDetails>{element.description}</AccordionDetails>
              <AccordionActions>
                <Link to={element.path} component={RouterLink} sx={{ display: 'contents' }}>
                  <Button size="small">Редагувати</Button>
                </Link>
              </AccordionActions>
            </Accordion>
          </>
        ))}
      </Box>
      <Paper elevation={3} sx={{ p: 2, my: 2, borderRadius: 2, fontStyle: 'italic' }}>
        <Typography variant="body2">
          Ці сторінки вцілому називають правовою інформацією або правовими документами на вебсайті. Вони забезпечують
          прозорість і допомагають захистити як користувачів, так і власників вебсайтів.
        </Typography>
      </Paper>
    </Container>
  </>
);

export default LegalInfo;
