import PropTypes from 'prop-types';
import { createContext, useState } from 'react';
import { PATH_USER_PHOTO, URL_GET_USER } from '../config';

// Створили контекст
export const AuthContext = createContext(null);

// Обернули провайдер
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const signin = (newUser, cb) => {
    setUser(newUser);
    // console.log('newUser: ', newUser);
    // при вході визвати також запит всієї інформації для додатка
    getUserDetail(newUser);
    cb();
  };

  const signout = (cb) => {
    setUser(null);
    cb();
  };

  // Перетворити результат API у потрібний формат
  const adaptUserObject = (obj) => ({
    ...obj,
    confirmed: obj.confirmed === 1,
    photoURL: obj.photoURL !== '' ? PATH_USER_PHOTO + obj.photoURL : '',
    photo_small: obj.photo_small !== '' ? PATH_USER_PHOTO + obj.photo_small : '',
    photo_medium: obj.photo_medium !== '' ? PATH_USER_PHOTO + obj.photo_medium : '',
    role: obj.ur_name,
    status: obj.us_name,
  });

  // Запросити повну інформацію для всього додатку
  const getUserDetail = (newUser) => {
    fetch(URL_GET_USER, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${newUser?.token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        // Адаптувати обєкт до потрібного формату
        json.user = adaptUserObject(json.user);
        // Додати в загальний стан
        setUser({
          ...newUser,
          dataUser: json.user,
        });
      })
      .catch((error) => console.error(error));
  };

  console.log('AuthProvider User: ', user);

  // ці три параметри об'єкта будуть доступні в любому компоненті
  // їх можна отримати через хук useContext
  // напр. const { user, signout } = useAuth();
  const value = { user, signin, signout, setUser, adaptUserObject };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// https://www.npmjs.com/package/prop-types
AuthProvider.propTypes = {
  children: PropTypes.node,
};
