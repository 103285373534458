import PropTypes from 'prop-types';
import { Alert, Slide, Snackbar } from '@mui/material';
import { createContext, useContext, useState } from 'react';

/**
 * 
   const { showSnackbar } = useSnackbar();
 * 
    showSnackbar({
      message: 'Hello',
      severity: 'info',
    });

    Тип повідомлень: success, info, warning, error
 */

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

export const SnackbarContext = createContext();
export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    message: '',
    severity: 'info',
    autoHideDuration: 5000,
    open: false,
  });

  const showSnackbar = ({ message = '', severity = 'info', autoHideDuration = 5000 } = {}) => {
    setSnackbar({
      message,
      severity,
      autoHideDuration,
      open: true,
    });
  };

  const hideSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={snackbar.autoHideDuration}
        onClose={hideSnackbar}
        TransitionComponent={SlideTransition}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        sx={{ cursor: 'pointer' }}
      >
        <Alert
          variant="filled" // variant="filled" or 'outlined' or ''
          onClose={hideSnackbar}
          severity={snackbar.severity}
          sx={{ maxWidth: 400 }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

SnackbarProvider.propTypes = { children: PropTypes.node };
