import { Box, Button, Container, Link, Stack, Typography, Paper } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Iconify from '../components/iconify';

const LegalInfoDetail = () => {
  const navigate = useNavigate();

  const handleEdit = (id) => {
    navigate(`edit/${id}`);
  };
  return (
    <>
      <Helmet>
        <title> Правова інформація </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Правова інформація
          </Typography>
          <Link to="add" component={RouterLink} sx={{ display: 'contents' }}>
            <Button variant="contained" href="" startIcon={<Iconify icon="eva:plus-fill" />}>
              Додати
            </Button>
          </Link>
        </Stack>

        <Stack spacing={2}>
          <Paper elevation={2} sx={{ p: 2, borderRadius: 2 }} gutterBottom>
            <Typography variant="h5">Ці сторінки вцілому називають правовою інформацією</Typography>
            <Typography variant="body1">
              Ці сторінки вцілому називають правовою інформацією або правовими документами на вебсайті. Вони
              забезпечують прозорість і допомагають захистити як користувачів, так і власників вебсайтів.
            </Typography>
            <Box sx={{ '& > :not(style)': { ml: 1 }, display: 'flex', justifyContent: 'flex-end' }}>
              <Button size="small" onClick={() => handleEdit(2)}>
                Редагувати
              </Button>
              <Button size="small">Видалити</Button>
            </Box>
          </Paper>
          <Paper elevation={2} sx={{ p: 2, borderRadius: 2 }} gutterBottom>
            <Typography variant="h5">Ці сторінки вцілому називають правовою інформацією</Typography>
            <Typography variant="body1">
              Ці сторінки вцілому називають правовою інформацією або правовими документами на вебсайті. Вони
              забезпечують прозорість і допомагають захистити як користувачів, так і власників вебсайтів. Ці сторінки
              вцілому називають правовою інформацією або правовими документами на вебсайті. Вони забезпечують прозорість
              і допомагають захистити як користувачів, так і власників вебсайтів. Ці сторінки вцілому називають правовою
              інформацією або правовими документами на вебсайті. Вони забезпечують прозорість і допомагають захистити як
              користувачів, так і власників вебсайтів.
              <Box sx={{ '& > :not(style)': { ml: 1 }, display: 'flex', justifyContent: 'flex-end' }}>
                <Button size="small" onClick={() => handleEdit(2)}>
                  Редагувати
                </Button>
                <Button size="small">Видалити</Button>
              </Box>
            </Typography>
          </Paper>
          <Paper elevation={2} sx={{ p: 2, borderRadius: 2 }} gutterBottom>
            <Typography variant="h5">Ці сторінки вцілому називають правовою інформацією</Typography>
            <Typography variant="body1">
              Ці сторінки вцілому називають правовою інформацією або правовими документами на вебсайті. Вони
              забезпечують прозорість і допомагають захистити як користувачів, так і власників вебсайтів.
            </Typography>
            <Box sx={{ '& > :not(style)': { ml: 1 }, display: 'flex', justifyContent: 'flex-end' }}>
              <Button size="small" onClick={() => handleEdit(2)}>
                Редагувати
              </Button>
              <Button size="small">Видалити</Button>
            </Box>
          </Paper>

          <Paper elevation={2} sx={{ p: 2, borderRadius: 2 }} gutterBottom>
            <Typography variant="h5">Ці сторінки вцілому називають правовою інформацією</Typography>
            <Typography variant="body1">
              Ці сторінки вцілому називають правовою інформацією або правовими документами на вебсайті. Вони
              забезпечують прозорість і допомагають захистити як користувачів, так і власників вебсайтів.
            </Typography>
            <Box sx={{ '& > :not(style)': { ml: 1 }, display: 'flex', justifyContent: 'flex-end' }}>
              <Button size="small" onClick={() => handleEdit(2)}>
                Редагувати
              </Button>
              <Button size="small">Видалити</Button>
            </Box>
          </Paper>
        </Stack>
      </Container>
    </>
  );
};

export default LegalInfoDetail;
