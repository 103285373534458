import { useState } from 'react';
import { Box, Button, Container, Link, Stack, Typography, Paper, CircularProgress } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Iconify from '../components/iconify';

const LegalInfoDetailEdit = () => {
  const [loading, setLoading] = useState(false);
  // Щоб зробити крок назад
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <>
      <Helmet>
        <title> Правова інформація </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Правова інформація
          </Typography>
        </Stack>

        <Stack>
          <Box
            sx={{
              '& > :not(style)': { ml: 1, mb: 1 },
              display: 'flex',
              justifyContent: 'center',
              mb: 3,
            }}
          >
            <Button
              variant="contained"
              startIcon={
                loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : <Iconify icon="eva:save-outline" />
              }
              // onClick={handleSubmit}
            >
              Зберегти
            </Button>

            <Button onClick={goBack} variant="outlined" startIcon={<Iconify icon="eva:arrow-back-fill" />}>
              Відмінити
            </Button>
          </Box>
        </Stack>

        <Box>Edit</Box>
      </Container>
    </>
  );
};

export default LegalInfoDetailEdit;
