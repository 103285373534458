import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Link,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
// import USERLIST from '../_mock/user';
import { useAuth } from '../hooks/useAuth';
import { PATH_GET_USERS_ADMIN, PATH_USER_PHOTO, URL_UPDATE_DELETE } from '../config';
import ProgressLinear from '../components/progress-linear';
import { useSnackbar } from '../hooks/useSnackbar';
import AlertDialog from '../components/dialog';

const TABLE_HEAD = [
  { id: 'name', label: "Ім'я", alignRight: false },
  { id: 'company', label: 'Компанія', alignRight: false },
  { id: 'role', label: 'Роль', alignRight: false },
  { id: 'isVerified', label: 'Перевірений', alignRight: false },
  { id: 'status', label: 'Статус', alignRight: false },
  { id: '' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserAdminPage() {
  const { showSnackbar } = useSnackbar();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user, signout } = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const idPopover = open ? 'simple-popover' : undefined;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openId, setOpenId] = useState(null);
  const [editPermission, setEditPermission] = useState(true);
  // AretDialog
  const [alertDialog, setAlertDialog] = useState(false);
  const [headerDialog, setHeaderDialog] = useState('');
  const [textDialog, setTextDialog] = useState('');

  const handleOpenMenu = (event, id) => {
    setAnchorEl(event.currentTarget);
    setOpenId(id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // const newSelecteds = USERLIST.map((n) => n.name);
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleDeleteUser = (id, showDialog) => {
    setAnchorEl(null);

    if (user.dataUser.role !== 'admin') {
      showSnackbar({
        message: 'Ви не можете видаляти  користувачів!',
        severity: 'error',
      });
      return;
    }

    if (user.dataUser.id === id) {
      showSnackbar({
        message: 'Ви не можете видалити себе!',
        severity: 'error',
      });
      return;
    }

    setAlertDialog(showDialog);
    setHeaderDialog(`Видалення запису! [#${id}]`);
    setTextDialog(`Ви впевнені, що хочете видалити цього користувача?`);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  // const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);
  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  // Перетворити результат API у потрібний формат
  const updateUsers = (usersArray) =>
    usersArray.map((user) => ({
      ...user,
      isVerified: user.confirmed === 1,
      avatarUrl: user.photo_small !== '' ? PATH_USER_PHOTO + user.photo_small : '',
      role: user.ur_name,
      status: user.us_name,
      name: `${user.name} ${user.surname} ${user.fatherName}`,
    }));

  const deleteUser = async (id) => {
    try {
      setLoading(true);

      const data = {
        id,
      };

      const response = await fetch(URL_UPDATE_DELETE, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      if (result.ok === 1 && result.status === 200) {
        getUsers();
        showSnackbar({
          message: 'Користувач видалений!',
        });
        setAlertDialog(false);
      } else {
        signout(() => navigate('/login', { replace: true }));
      }
    } catch (error) {
      showSnackbar({
        message: error.message,
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  // Запросити дані з API
  const getUsers = async () => {
    try {
      setLoading(true);
      const response = await fetch(PATH_GET_USERS_ADMIN, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      const result = await response.json();

      // Нам потрібен лише правильний результат
      // інакше - вийти
      if (result.ok === 1 && result.status === 200) {
        // Потрібно перетворити відповідь у відповідний формат
        // Перебрати маисв та змінити кожен об'єкт
        setUsers(updateUsers(result.users));
      } else {
        signout(() => navigate('/login', { replace: true }));
      }
    } catch (error) {
      // console.error('Помилка при отриманні даних:', error);
      showSnackbar({
        message: error.message,
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Запит всіх користувачів
    getUsers();

    // Правила додавання:
    // Адмін - може додавати
    if (user.dataUser.role === 'admin') {
      setEditPermission(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(open);

  return (
    <>
      <Helmet>
        <title> Користувачі адмін панелі </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Користувачі адмін панелі
          </Typography>
          <Link to="add" component={RouterLink} sx={{ display: 'contents' }}>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} disabled={editPermission}>
              Додати
            </Button>
          </Link>
        </Stack>

        <Card>
          {!loading || <ProgressLinear />}
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  // rowCount={USERLIST.length}
                  rowCount={users.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, name, role, status, company, avatarUrl, isVerified } = row;
                    const selectedUser = selected.indexOf(name) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={name} src={avatarUrl} />
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{company}</TableCell>

                        <TableCell align="left">{role}</TableCell>

                        <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell>

                        <TableCell align="left">
                          <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, id)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Не знайдено
                          </Typography>

                          <Typography variant="body2">
                            Не знайдено результатів для &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Спробуйте перевірити друкарські помилки або використати повні слова.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            // count={USERLIST.length}
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        id={idPopover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          },
        }}
      >
        <Link to={`edit/${openId}`} component={RouterLink} sx={{ display: 'contents' }}>
          <MenuItem>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Редагувати
          </MenuItem>
        </Link>

        <MenuItem sx={{ color: 'error.main' }} onClick={() => handleDeleteUser(openId, true)}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Видалити
        </MenuItem>
      </Popover>

      <AlertDialog
        inert
        header={headerDialog}
        text={textDialog}
        show={alertDialog}
        onHide={() => setAlertDialog(false)}
        onContinue={() => deleteUser(openId)}
        agree={`Видалити`}
        disagree={'Закрити'}
      />
    </>
  );
}
