const account = {
  id: '',
  name: '',
  surname: '',
  fatherName: '',
  phone: '',
  email: '',
  company: '',
  profession: '',
  photoURL: '',
  photo_medium: '',
  photo_small: '',
  confirmed: false,
  status: '',
};

export default account;
