// component
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import PolicyTwoToneIcon from '@mui/icons-material/PolicyTwoTone';
// https://mui.com/material-ui/material-icons/?theme=Two+tone&query=lang
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Мовні набори',
    path: '/dashboard/lang-sets',
    icon: <LanguageTwoToneIcon />,
  },
  {
    title: 'Користувачі адмін панелі',
    path: '/dashboard/user-admin',
    icon: icon('ic_user'),
  },
  {
    title: 'Користувачі',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  {
    title: 'Правова інформація',
    path: '/dashboard/legal-information',
    icon: <PolicyTwoToneIcon />,
  },
  {
    title: 'Product',
    path: '/dashboard/products',
    icon: icon('ic_cart'),
  },
  {
    title: 'Blog',
    path: '/dashboard/blog',
    icon: icon('ic_blog'),
  },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
];

export default navConfig;
