// import sha1 from 'sha1';
import { MuiFileInput } from 'mui-file-input';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Stack,
  Button,
  Typography,
  TextField,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CardMedia,
  Card,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Iconify from '../components/iconify';
import { URL_UPDATE_ADD, URL_UPDATE_USER_PHOTO } from '../config';
import { useAuth } from '../hooks/useAuth';
import account from '../_mock/account';
import { useSnackbar } from '../hooks/useSnackbar';

export default function UserAdminPageAdd() {
  const { showSnackbar } = useSnackbar();
  const { user } = useAuth();
  const [myInfo, setMyInfo] = useState(account);
  const [isValidEmail, setIsValidEmail] = useState({ isValid: true, errorText: '' });
  const [isValidName, setIsValidName] = useState({ isValid: true, errorText: '' });
  const [loading, setLoading] = useState(false);
  const [editPermission, setEditPermission] = useState(true);

  // Інтпут типу додати файл
  const [file, setFile] = useState(null);

  const handlePhoto = (newFile) => {
    setFile(newFile);
  };

  // Текстові поля
  const handleText = (event, field) => {
    setMyInfo((previousState) => ({ ...previousState, [field]: event.target.value }));
  };

  // Чекбокс
  const handleConfirmed = (event) => {
    setMyInfo((previousState) => ({ ...previousState, confirmed: event.target.checked }));
  };
  // Випадаючий список
  const handleStatus = (event) => {
    setMyInfo((previousState) => ({ ...previousState, status: event.target.value }));
  };

  // Щоб зробити крок назад
  const navigate = useNavigate();
  // const goBack = () => navigate(-1);

  // Відправка форми
  // Ця функція аналогічно редагуванню свого профілю,
  // тільки тут не змінюється стан програми, якщо ти коригуєш чужий профіль
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Отримати дані форми
    if (myInfo.name === '') {
      setIsValidName({ isValid: false, errorText: "Ім'я обов'язкове!" });
      return;
      // eslint-disable-next-line no-else-return
    } else {
      setIsValidName({ isValid: true, errorText: '' });
    }

    if (myInfo.email === '') {
      setIsValidEmail({ isValid: false, errorText: "Електронна пошта обов'язкова!" });
      return;
      // eslint-disable-next-line no-else-return
    } else {
      setIsValidEmail({ isValid: true, errorText: '' });
    }

    setLoading(true);

    try {
      // надсилаємо асинхронним запитом
      const res = await fetch(URL_UPDATE_ADD, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
        body: JSON.stringify(myInfo),
      });
      const result = await res.json();

      if (result.ok === 1 && result.status === 201 && result.id) {
        if (result.id && file) {
          // Визвати функцію доавання зображення
          if (await sendImage(result.id)) {
            showSnackbar({
              message: 'Дані та зображення успішно збережені!',
            });
          } else {
            showSnackbar({
              message: 'Дані збережені але без зображення!',
              severity: 'warning',
            });
          }
        } else {
          showSnackbar({
            message: 'Дані успішно збережені!',
          });
        }
      } else {
        showSnackbar({
          message: 'Помилка збереження!',
          severity: 'error',
        });
      }
    } catch (error) {
      showSnackbar({
        message: error.message,
        severity: 'error',
      });
    } finally {
      setLoading(false);
      navigate('..', { relative: 'path' });
    }
  };

  // Надіслати зображення
  const sendImage = async (id) => {
    if (!id) {
      return false;
    }

    try {
      const formData = new FormData();
      formData.append('photoURL', file);
      formData.append('id', id);

      // надсилаємо асинхронним запитом
      const res = await fetch(URL_UPDATE_USER_PHOTO, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
        body: formData,
      });
      const result = await res.json();
      if (result.ok === 1 && result.status === 200) {
        return true;
      }
      return false;
    } catch (error) {
      // console.error(error);
      showSnackbar({
        message: error.message,
        severity: 'error',
      });
      return false;
    }
  };

  useEffect(() => {
    // Правила додавання:
    // Адмін - може додавати
    if (user.dataUser.role === 'admin') {
      setEditPermission(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component="form" id="formEditUser" noValidate autoComplete="off">
      <Helmet>
        <title>Додаємо користувача адмін панелі </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Додаємо користувача адмін панелі
          </Typography>
        </Stack>
        <Stack>
          <Box
            sx={{
              '& > :not(style)': { ml: 1, mb: 1 },
              display: 'flex',
              justifyContent: 'center',
              mb: 3,
            }}
          >
            <Button
              variant="contained"
              startIcon={
                loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : <Iconify icon="eva:save-outline" />
              }
              onClick={handleSubmit}
              disabled={editPermission}
            >
              Зберегти
            </Button>

            <Button
              onClick={() => navigate('/dashboard/user-admin')}
              variant="outlined"
              startIcon={<Iconify icon="eva:close-fill" />}
            >
              Закрити
            </Button>
          </Box>
        </Stack>
        <Box
          sx={{
            '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
          }}
        >
          <input type="hidden" id="tf_id" value={myInfo.id} />
          <TextField
            id="tf_surname"
            label="Прізвище"
            variant="outlined"
            value={myInfo.surname}
            onChange={(e) => handleText(e, 'surname')}
            disabled={editPermission}
          />
          <TextField
            id="tf_name"
            label="Ім'я"
            variant="outlined"
            value={myInfo.name}
            onChange={(e) => handleText(e, 'name')}
            error={!isValidName.isValid}
            helperText={!isValidName.isValid ? isValidName.errorText : ''}
            disabled={editPermission}
          />
          <TextField
            id="tf_fatherName"
            label="По батькові"
            variant="outlined"
            value={myInfo.fatherName}
            onChange={(e) => handleText(e, 'fatherName')}
            disabled={editPermission}
          />
        </Box>
        <Box
          sx={{
            '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
          }}
        >
          <TextField
            id="tf_phone"
            label="Номер телефону"
            variant="outlined"
            value={myInfo.phone}
            onChange={(e) => handleText(e, 'phone')}
            disabled={editPermission}
          />
          <TextField
            id="tf_email"
            label="Електронна пошта"
            variant="outlined"
            value={myInfo.email}
            onChange={(e) => handleText(e, 'email')}
            error={!isValidEmail.isValid}
            helperText={!isValidEmail.isValid ? isValidEmail.errorText : ''}
            disabled={user.dataUser.role !== 'admin'}
          />
        </Box>
        <Box
          sx={{
            '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
          }}
        >
          <TextField
            id="tf_company"
            label="Компанія"
            variant="outlined"
            value={myInfo.company}
            onChange={(e) => handleText(e, 'company')}
            disabled={editPermission}
          />
          <TextField
            id="tf_profession"
            label="Професія"
            variant="outlined"
            value={myInfo.profession}
            onChange={(e) => handleText(e, 'profession')}
            disabled={editPermission}
          />
        </Box>
        <Box
          sx={{
            '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
          }}
        >
          <FormGroup>
            <FormControlLabel
              fullWidth
              disabled={user.dataUser.role !== 'admin'}
              control={
                <Checkbox
                  id="confirmed"
                  checked={myInfo.confirmed}
                  onChange={handleConfirmed}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Перевірений"
            />
          </FormGroup>
        </Box>
        <Box
          sx={{
            '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
          }}
        >
          <FormControl fullWidth disabled={user.dataUser.role !== 'admin'}>
            <InputLabel id="status-select">Статус</InputLabel>
            <Select labelId="status-select" id="status" value={myInfo.status} label="Статус" onChange={handleStatus}>
              <MenuItem value={'active'}>Активний</MenuItem>
              <MenuItem value={'banned'}>Заблокований</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            '& > :not(style)': { my: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
          }}
        >
          <MuiFileInput
            id="photoURL"
            label="Вибрати фото"
            variant="outlined"
            value={file}
            onChange={handlePhoto}
            getInputText={(value) => (value ? 'Файл вибраний' : '')}
            accept="image/*,.png,.jpg"
            disabled={editPermission}
          />
        </Box>
        {!myInfo.photoURL || (
          <Card sx={{ maxWidth: 128 }}>
            <CardMedia component="img" alt="" height="128" image={myInfo.photoURL} />
          </Card>
        )}
      </Container>
    </Box>
  );
}
